import { Fragment } from "react";

const DIALOG_BUTTON_SETS = ["OK", "DELETE_CANCEL"] as const;
export type DialogButtonSet = typeof DIALOG_BUTTON_SETS[number];

interface DialogButtonInfo {
    buttons: { text:string, className:string }[];
}

const DIALOG_BUTTON_SET: { [key in DialogButtonSet]: DialogButtonInfo } = {
    OK: {
        buttons: [{ text:"OK", className:"is-success" }]
    },
    DELETE_CANCEL: {
        buttons: [
            { text:"Delete", className:"is-danger" },
            { text:"Cancel", className:"" }
        ]
    }
}

interface Props {
    onClose: (result?: number) => void;
    header?: string;
    message: string | string[];
    buttons: DialogButtonSet;
}

export const Dialog = (props: Props) => {

    const buttonsInfo = DIALOG_BUTTON_SET[props.buttons];

    return (
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.header ?? ""}</p>
                    <button className="delete" aria-label="close" onClick={() => props.onClose(undefined)} />
                </header>
                <section className="modal-card-body">
                    { (typeof props.message === "string") ? (
                        <span>{props.message}</span>
                    ) : (
                        <span>
                            {props.message.map((m,i) => (
                                <Fragment key={i}>{m}<br/></Fragment>
                            ))}
                        </span>
                    )}
                </section>
                <footer className="modal-card-foot">
                    { buttonsInfo.buttons.map((b,i) => (
                        <button key={i} className={"button " + b.className} onClick={() => props.onClose(i)}>{b.text}</button>
                    ))}
                </footer>
            </div>
        </div>
    )
}