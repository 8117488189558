import { useEffect, useState } from "react"

interface Props {
    onClose: () => void;
    name: string;
    onSubmit: (name:string) => void;
}

export const ViewNameEditModal = (props: Props) => {
    const [ name, setName ] = useState("");

    useEffect(() => {
        setName(props.name);

    }, [ props.name ]);

    return (
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Edit View Name</p>
                    <button className="delete" aria-label="close" onClick={props.onClose} />
                </header>
                <section className="modal-card-body">
                    <div className="content">
                        <input type="text" className="input" value={name} onChange={e => setName(e.target.value)} />
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className={"button is-success"} onClick={() => props.onSubmit(name)}>Submit</button>
                </footer>
            </div>
        </div>
    )
}