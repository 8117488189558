import Enumerable from "linq";
import { useMemo } from "react";
import { Link, linkToKey, State, ViewInfo } from "./UmlTypes";

interface Props {
    allStates: State[];
    allLinks: Link[];
    allViews: ViewInfo[];
    onClose: () => void;
}

export const CoverageModal = (props: Props) => {

    const list = useMemo(() => {
        //state, link に重複があっても問題ないっぽい
        const stateNameMap = Enumerable.from(props.allStates).toDictionary(s => s.name, s => s.dispName);
        const countMap = Enumerable.from(props.allLinks)
                                    .toDictionary(l => linkToKey(l), l => ({
                                        from: l.from,
                                        to: l.to,
                                        dispFrom: stateNameMap.get(l.from),
                                        dispTo: stateNameMap.get(l.to),
                                        count: 0
                                    }));
        for (const view of props.allViews) {
            for (const key of view.linkKeySet) {
                if (countMap.contains(key)) {
                    countMap.get(key).count++;
                }
            }
        }
        return countMap.toEnumerable()
                    .select(p => p.value)
                    .orderBy(l => l.from)
                    .toArray();

    }, [ props.allStates, props.allLinks, props.allViews ]);

    return (
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Coverage of Links</p>
                    <button className="delete" aria-label="close" onClick={props.onClose} />
                </header>
                <section className="modal-card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>FROM</th>
                                <th>TO</th>
                                <th>COUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            { list.map(l => (
                                <tr key={`${l.from}___${l.to}`}>
                                    <td>{l.dispFrom ?? `(${l.from})`}</td>
                                    <td>{l.dispTo ?? `(${l.to})`}</td>
                                    <td className={l.count === 0 ? "has-background-warning" : undefined}>{l.count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    )
}