import { useEffect } from "react"

export const AdUnit = () => {

    useEffect(() => {
        ///@ts-ignore
        const adsByGoogle: any | undefined = window.adsbygoogle;

        if (adsByGoogle && process.env.NODE_ENV !== "development") {
            adsByGoogle.push({});
        }

    }, []);

    return (
        <ins className="adsbygoogle"
            style={{ display:"block" }}
            data-ad-client="ca-pub-7250330026054445"
            data-ad-slot="7226688094"
            data-ad-format="auto"
            data-full-width-responsive="true"
        />
    )
}