import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { IconButton } from "./IconButton";
import { Link, linkToKey, State, ViewInfo } from "./UmlTypes";

interface Props {
    onRefreshRequest: () => void;
    views: ViewInfo[];
    allStates: State[];
    allLinks: Link[];
    currentViewIndex: number | undefined;
    onCurrentViewIndexChanged: (index: number) => void;
    onViewUpdated: (index: number, view: ViewInfo) => void;
    onAddView: () => void;
}

const groupState = (states: State[], level:number, parent:string | undefined): GroupedState[] => {
    if (level !== 0 && parent == null) throw new Error(`invaild param lv=${level}`);

    const targets = states.filter(s => parent == null ? s.parent == null : s.parent === parent);
    return targets.map(t => ({ ...t, level, children: groupState(states, level + 1, t.name)}));
}

export const ViewsPanel = (props: Props) => {
    const currentView = useMemo(() => {
        if (props.currentViewIndex == null) return undefined;
        return props.views[props.currentViewIndex];

    }, [ props.currentViewIndex, props.views ]);

    return (
        <div className="panel">
            <div className="panel-heading is-flex is-justify-content-space-between">
                <span>Views</span>
                <IconButton faClass="fas fa-sync" type="warning" onClick={props.onRefreshRequest} text="Reload UML" />
            </div>
            <div className="panel-tabs scroll-horizontal is-justify-content-flex-start text-nowrap">
                { props.views.map((v,i) => (
                    <a onClick={() => props.onCurrentViewIndexChanged(i)} key={`${i}_${v.name}`} className={props.currentViewIndex === i ? "is-active" : ""}>{v.name}</a>
                ))}
                <a onClick={props.onAddView}>+ Add</a>
            </div>
            <ViewTabContent
                allStates={props.allStates}
                allLinks={props.allLinks}
                view={currentView}
                viewIndex={props.currentViewIndex}
                onUpdate={view => props.onViewUpdated(props.currentViewIndex!, view)}
            />
        </div>
    )

}

type GroupedState = State & { level: number, children: GroupedState[] };

const ViewTabContent = (props: {
    allStates: State[];
    allLinks: Link[];
    view: ViewInfo | undefined,
    viewIndex: number | undefined,
    onUpdate: (view:ViewInfo) => void
}) => {
    const [ isSyncStates, setIsSyncStates ] = useState(false);

    useEffect(() => {
        setIsSyncStates(false);

    }, [ props.viewIndex ]);

    const groupedStates = useMemo(() => {
        return groupState(props.allStates, 0, undefined);

    }, [ props.allStates ]);

    const stateDispNameMap = useMemo(() => {
        const m = new Map<string, string>();
        props.allStates.forEach(s => m.set(s.name, s.dispName));
        return m;

    }, [ props.allStates ]);

    useEffect(() => {
        if (!isSyncStates) return;
        if (props.view == null) return;

        //linksに含まれるstateをstatesに反映
        const allLinks = props.allLinks.map(l => ({ ...l, key: linkToKey(l) }));
        const links = [...props.view.linkKeySet].map(k => allLinks.find(l => l.key === k))
            .filter(l => l != null) as Link[];
        const states = links.map(l => l.from);
        states.push(...links.map(l => l.to));
        
        props.onUpdate({ ...props.view, stateNameSet: new Set(states) });

    }, [ isSyncStates, props.view?.linkKeySet, props.allLinks ]);

    const onStateChange = (name: string, checked: boolean) => {
        if (props.view == null) return;

        const newState = new Set(props.view.stateNameSet);
        if (checked) {
            newState.add(name);
        } else {
            newState.delete(name);
        }
        props.onUpdate({ ...props.view, stateNameSet:newState });
    }

    const onLinkChange = (key: string, checked: boolean) => {
        if (props.view == null) return;

        const newLinks = new Set(props.view.linkKeySet);
        if (checked) {
            newLinks.add(key);
        } else {
            newLinks.delete(key);
        }

        props.onUpdate({ ...props.view, linkKeySet:newLinks });
    }

    const view = props.view;
    if (view == null) return <></>

    const StateCheckbox = ({ state } : { state: GroupedState }) => {
        return (<>
            <label className={"checkbox mb-1 indent-" + state.level}>
                <input type="checkbox" disabled={isSyncStates}
                    checked={view.stateNameSet.has(state.name)}
                    onChange={e => onStateChange(state.name, e.target.checked)} 
                />
                <span className={classNames("is-size-6", {"has-text-grey-light": isSyncStates})}>{state.dispName}</span>
                <span className="has-text-info ml-1 is-size-7">{state.name}</span>
                { state.type && (
                    <span className="has-text-info ml-1 is-size-7">({state.type})</span>
                )}
            </label>
            { state.children.map(ch => <StateCheckbox key={ch.name} state={ch} />)}
        </>);
    }

    const LinkCheckbox = ({ link }: { link: Link & { key: string }}) => {
        const from = stateDispNameMap.get(link.from);
        const to = stateDispNameMap.get(link.to);
        return (
            <label className="checkbox mb-1">
                <input type="checkbox" checked={view.linkKeySet.has(link.key)}
                    onChange={e => onLinkChange(link.key, e.target.checked)}
                />
                { from ? (
                    <span className="is-size-6">{from}</span>
                ) : (
                    <span className="is-size-7">({link.from})</span>
                )}
                <span className="is-size-6 has-text-info">{"-->"}</span>
                { to ? (
                    <span className="is-size-6">{to}</span>
                ) : (
                    <span className="is-size-7">({link.to})</span>
                )}
                { link.comment && (
                    <span className="is-size-7 has-text-info ml-1">: {link.comment}</span>
                )}
            </label>
        );
    }

    return (<>
        <div className="panel-block">
            <div className="box scroll-vertical max-height-medium is-flex is-flex-direction-column w-100">
                <div className="is-flex">
                    <h4 className="title is-4">states</h4>
                    <label className="checkbox mt-1 ml-5">
                        <input type="checkbox" checked={isSyncStates} onChange={e => setIsSyncStates(e.target.checked)} />
                        Sync with links
                    </label>
                </div>
                { groupedStates.map(s => (
                    <StateCheckbox key={s.name} state={s} />
                ))}
            </div>
        </div>
        <div className="panel-block">
            <div className="box scroll-vertical max-height-medium is-flex is-flex-direction-column w-100">
                <h4 className="title is-4">links</h4>
                { props.allLinks
                    .map(l => ({ ...l, key: linkToKey(l) }))
                    .map(l => (
                        <LinkCheckbox key={l.key} link={l} />
                ))}
            </div>
        </div>
    </>)
}