import { LINE_BREAK, toRawText } from "./UmlConverter";
import { Link, linkToKey, ProjectInfo, State, ViewInfo } from "./UmlTypes";

interface ProjectJsonView {
    name: string,
    states:string[],
    links:string[]
}
interface ProjectJson {
    uml: string;
    headerText: string;
    states: Partial<State>[];
    links: Partial<Link>[];
    views: Partial<ProjectJsonView>[];
}

export const parseProjectJson = (content: string): ProjectInfo | undefined => {
    const info = JSON.parse(content) as Partial<ProjectJson>;

    if (info.uml == null || info.views == null) return undefined;

    return {
        rawUml: info.uml,
        headerText: info.headerText ?? "",
        states: (info.states ?? []).filter(s => s.name != null).map(s => ({ ...s, name: s.name!, dispName:s.dispName ?? s.name! })),
        links: (info.links ?? []).filter(l => l.from != null && l.to != null).map(l => ({ ...l, from:l.from!, to: l.to!, comment:l.comment ?? "" })),
        views: info.views.map(v => ({
            name: v.name ?? "_",
            stateNameSet: new Set(v.states ?? []),
            linkKeySet: new Set(v.links ?? [])
        })),
    }
}

export const buildProjectJson = (proj: ProjectInfo): string => {
    const json: ProjectJson = {
        uml: proj.rawUml,
        views: proj.views.map(v => ({
            name: v.name,
            states: [...v.stateNameSet],
            links: [ ...v.linkKeySet]
        })),
        headerText: proj.headerText,
        states: proj.states,
        links: proj.links
    };
    return JSON.stringify(json, null, 2);
}

export const buildMarkdown = (proj: Pick<ProjectInfo, "headerText"|"views"|"links"|"states">) => {

    return proj.views.map(v => buildMarkdownOfView(proj, v)).join(LINE_BREAK + LINE_BREAK);
}

const buildMarkdownOfView = (proj: Pick<ProjectInfo, "headerText"|"links"|"states">, view: ViewInfo) => {
    const lines = [ "## " + view.name, "" ];

    lines.push("```plantuml", "@startuml");

    lines.push(toRawText({ 
        headerText: proj.headerText,
        states:proj.states.filter(s => view.stateNameSet.has(s.name)),
        links: proj.links.filter(l => view.linkKeySet.has(linkToKey(l)))
    }));

    lines.push("@enduml", "```");

    return lines.join(LINE_BREAK);
}