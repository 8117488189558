import { useState } from "react"
import classNames from "classnames";

interface Props {
    onImport: (files: FileList | null) => void;
    onExport: (type: "project"|"markdown") => void;
    onAnalyzeCoverage: () => void;
    onAboutClick: () => void;
}

export const Header = (props: Props) => {
    const [ isOpen, setIsOpen ] = useState(false);

    return (
        <nav className="navbar" role="navigation" area-label="main navigation">
            <div className="navbar-brand">
                <div className="navbar-item p-0">
                    <img src="header.png" alt="Transit UML" style={{ maxHeight:"52px" }} />
                </div>
                <a role="button" className={classNames("navbar-burger", { "is-active":isOpen })}
                    aria-label="menu" aria-expanded="false" data-target="navMenu"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </a>
            </div>

            <div id="navMenu" className={classNames("navbar-menu", { "is-active":isOpen })}>
                <div className="navbar-start">
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">Import</a>
                        <div className="navbar-dropdown is-boxed">
                            <a className="navbar-item">
                                <label className="is-clickable">
                                <input type="file" className="is-hidden" accept=".json" onChange={e => props.onImport(e.target.files)} />
                                Project
                            </label>
                            </a>
                        </div>
                    </div>
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">Export</a>
                        <div className="navbar-dropdown is-boxed">
                            <a className="navbar-item" onClick={() => props.onExport("project")}>Project</a>
                            <a className="navbar-item" onClick={() => props.onExport("markdown")}>Markdown</a>
                        </div>
                    </div>

                    <a className="navbar-item" onClick={props.onAnalyzeCoverage}>Coverage</a>
                    <a className="navbar-item" onClick={props.onAboutClick}>About</a>
                </div>
            </div>
        </nav>
    )
}