interface Props {
    onClose: () => void;
    version: string | undefined;
}

export const AboutModal = (props: Props) => {

    return (
        <div className="modal is-active">
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">About Transit UML</p>
                    <button className="delete" aria-label="close" onClick={props.onClose} />
                </header>
                <section className="modal-card-body">
                    <div className="content">
                        <h1>How to Use</h1>
                        <p><strong>Transit UML</strong> is a tool to create Screen Tansition Diagrams using <a href="https://plantuml.com/">PlantUML</a> and manage it in several views.</p>
                        <p>You can write diagrams in <strong>UML</strong> area by PlantUML State Diagram syntax.</p>
                        <p>In <strong>Views</strong> area, you can create some views and select which states and links are contained in each view.</p>
                        <p>In <strong>Preview</strong> area, you can see the diagram drown by PlantUML server, and the uml text of the selected view.</p>

                        <h1>Terms of Use</h1>
                        <p>You can use <strong>Transit UML</strong> and the images generated by this service for any porpose, as long as the <a href="https://plantuml.com/en/faq#479a824c6c37f0b6">terms of PlantUML</a> allow it.</p>
                        <p>This service may be terminated without notice.</p>

                        <h1>Privacy Policy</h1>
                        <p>Transit UML sends texts you write to the PlantUML server in order to draw diagrams.</p>
                        <h2>Advertisements We Use</h2>
                        <p>We may use third party advertisements (such as Google AdSense) on Transit UML to support our service. Some of these advertisers may use cookies to serve ads based on a user's prior visits to our website or other websites.</p>
                        <p>You can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="http://www.aboutads.info/choices/">www.aboutads.info</a>.</p>
                        <h2>Google Analytics</h2>
                        <p>We use Google Analytics on our site. It uses cookies to help the website analyse how users use the website.</p>
                        <p>The information generated by the cookie about your use of the websites (including your IP address) will be transmitted to and stored by Google on their servers. Google will use this information for the purpose of evaluating your use of the websites compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google.</p>
                        <p>You can refuse the use of cookies by selecting the appropriate settings on your browser.</p>
                        <p>To find out more, see <a href="https://policies.google.com/technologies/partner-sites">policies.google.com/technologies/partner-sites</a>.</p>

                        <h1>Contact</h1>
                        <p>Twitter: <a href="https://twitter.com/oika">@oika</a></p>

                        <div className="is-size-7 has-text-grey is-flex is-justify-content-center">
                            <span>copyright © Since 2021 oika</span>
                            { props.version != null && (
                                <span> | v{props.version}</span>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}