import { IconButton } from "./IconButton"

interface Props {
    uml: string;
    onUmlChanged: (uml: string) => void;
    onCheckDuplication: () => void;
    onOpenModalRequrest: () => void;
}

export const UmlInputPanel = (props: Props) => {

    return (
        <div className="panel">
            <div className="panel-heading is-justify-content-space-between is-flex">
                <span>
                    <span>UML</span>
                    <IconButton faClass="fas fa-expand-arrows-alt" type="text" onClick={props.onOpenModalRequrest} title="expand" />
                </span>
                <IconButton type="warning" faClass="fas fa-check" onClick={props.onCheckDuplication} text="Check Duplication" />
            </div>
            <div className="panel-block">
                <div className="control align-items-stretch is-flex">
                    <textarea className="textarea is-small" rows={6}
                        value={props.uml}
                        onChange={e => props.onUmlChanged(e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}