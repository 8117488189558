interface Props {
    type: "text"|"danger"|"warning";
    faClass: string;
    onClick: () => void;
    title?: string;
    className?: string;
    text?: string;
}

export const IconButton = (props: Props) => {
    const buttonClass = props.type === "danger" ? "is-danger"
                    : props.type === "text" ? "is-text no-deco"
                    : props.type === "warning" ? "is-warning"
                    : "";

    return (
        <button className={`button is-small ${buttonClass} ${props.className ?? ""}`} onClick={props.onClick} title={props.title}>
            <span className="icon">
                <i className={props.faClass} />
            </span>
            { props.text && (
                <span>{props.text}</span>
            )}
        </button>
    )
}