import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DialogContext } from './DialogContext';
import { Header } from './Header';
import { PlantUmlView } from './Preview/PlantUmlView';
import { buildMarkdown, buildProjectJson, parseProjectJson } from './project-file-parser';
import { parseRawText, toRawText } from './UmlConverter';
import { UmlInputPanel } from './UmlInputPanel';
import { ViewsPanel } from './ViewsPanel';
import { saveAs } from 'file-saver';
import { State, Link, ViewInfo, linkToKey } from './UmlTypes';
import { CoverageModal } from './CoverageModal';
import { AboutModal } from './AboutModal';
import { ViewNameEditModal } from './ViewNameEditModal';
import { UmlInputModal } from './UmlInputModal';
import { AdUnit } from './AdUnit';
import { IconButton } from './IconButton';

const APP_VERSION = process.env.REACT_APP_VERSION;

export const Content = (props: {
    onContentChangedChange: (changed: boolean) => void
}) => {
    const [ editingUml, setEditingUml ] = useState<string>("");
    const [ headerText, setHeaderText ] = useState("");
    const [ allStates, setAllStates ] = useState<State[]>([]);
    const [ allLinks, setAllLinks ] = useState<Link[]>([]);
    const [ allViews, setAllViews ] = useState<ViewInfo[]>([]);
    const [ currentViewIndex, setCurrentViewIndex ] = useState<number>();
    const [ projFile, setProjFile ] = useState<string>();
    const [ projFileName, setProjFileName ] = useState<string>();
    const [ isCoverageShown, setIsCoverageShown ] = useState(false);
    const [ isAboutShown, setIsAboutShown ] = useState(false);
    const [ isViewNameEditing, setIsViewNameEditing ] = useState(false);
    const [ isUmlEditorShown, setIsUmlEditorShown ] = useState(false);

    const dialogContext = useContext(DialogContext);

    useEffect(() => {
        const uml = 
`/'===============================
    You can write settings here.
================================'/
hide empty description
skinparam state {
  BackgroundColor<<MODAL>> orange
}

/'===============================
    Define states here.
================================'/
state "LOGIN" as login
state "SIGN UP" as signup
state "SEND MAIL" as sendmail
state "TOP PAGE" as top {
  state "DASHBOARD" as top.board
  state "NOTIFICATION" as top.notice
  state "NOTIFICATION DETAIL" as top.notice.detail<<MODAL>>
}

/'===============================
    Write all links here.
================================'/
login --> top: submit user/pass
login --> signup
signup --> sendmail
top.notice --> top.notice.detail: show detail
`       ;
        setEditingUml(uml);
        const parsed = parseRawText(uml, true);
        setHeaderText(parsed.headerText);
        setAllStates(parsed.states);
        setAllLinks(parsed.links);

        setAllViews([
            {
                name:"registration", 
                stateNameSet: new Set(["login","signup","sendmail"]),
                linkKeySet: new Set([
                    linkToKey({ from:"login", to:"signup" }),
                    linkToKey({ from:"signup", to:"sendmail" })
                ])
            },
            {
                name:"top page",
                stateNameSet: new Set(["login", "top", "top.board", "top.notice", "top.notice.detail"]),
                linkKeySet: new Set([
                    linkToKey({ from:"login", to:"top" }),
                    linkToKey({ from:"top.notice", to:"top.notice.detail" })
                ])
            }
        ]);
        setCurrentViewIndex(0);
    }, []);

    const { onContentChangedChange } = props;

    useEffect(() => {
        if (projFile == null) return;

        const info = parseProjectJson(projFile);
        if (info == null) {
            dialogContext.showDialog("ERROR", "Invalid Project File.", "OK");
            return;
        }

        setEditingUml(info.rawUml);
        setHeaderText(info.headerText);
        setAllStates(info.states);
        setAllLinks(info.links);
        setAllViews(info.views);
        setCurrentViewIndex(info.views.length === 0 ? undefined : 0);
        onContentChangedChange(false);

    }, [ projFile, onContentChangedChange ])

    const currentView = useMemo(() => {
        if (currentViewIndex == null) return undefined;
        return allViews[currentViewIndex];

    }, [ currentViewIndex, allViews ]);

    const currentViewUml = useMemo(() => {
        if (currentView == null) return undefined;
        const states = allStates.filter(s => currentView.stateNameSet.has(s.name));
        const links = allLinks.filter(l => currentView.linkKeySet.has(linkToKey(l)));

        return toRawText({ headerText, states, links });

    }, [ currentView, headerText, allStates, allLinks ]);

    const onImport = async (files:FileList|null) => {
        if (files == null || files.length !== 1) return;
        const file = files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            reader.onload = null;
            if (reader.result == null || typeof(reader.result) !== "string") {
                if (reader.error != null) {
                    await dialogContext.showDialog("ERROR", "Failed to load project file.", "OK");
                }

                setProjFileName(undefined);
                setProjFile(undefined);
                return;
            }
            setProjFileName(file.name);
            setProjFile(reader.result);
        }

        reader.readAsText(file);
    }

    const onExport = (type: "project"|"markdown") => {
        if (type === "project") {
            exportProject();
            return;
        }
        if (type === "markdown") {
            exportMarkdown();
            return;
        }
    }

    const exportProject = () => {
        const json = buildProjectJson({
            headerText,
            links: allLinks,
            states: allStates,
            views: allViews,
            rawUml: editingUml
        });

        const blob = new Blob([ json ], { type : "text/json;charset=utf-8" });
        saveAs(blob, projFileName ?? "my-project.json");
        props.onContentChangedChange(false);
    }

    const exportMarkdown = () => {
        const md = buildMarkdown({
            headerText,
            links: allLinks,
            states: allStates,
            views: allViews,
        });

        const blob = new Blob([ md ], { type : "text/plain;charset=utf-8" });

        let fileName: string;
        if (projFileName == null) {
            fileName = "my-project.md";
        } else if (projFileName.toUpperCase().endsWith(".JSON")) {
            fileName = projFileName.slice(0, ".json".length * -1) + ".md";
        } else {
            fileName = projFileName + ".md";
        }

        saveAs(blob, fileName);
    }

    const onUmlChange = (uml: string) => {
        setEditingUml(uml);
        props.onContentChangedChange(true);
    }

    const onCheckDuplication = () => {
        const parsed = parseRawText(editingUml, false);

        const stateKeys = new Set<string>();
        const duplicatedStates: string[] = [];
        for (const state of parsed.states) {
            if (stateKeys.has(state.name)) {
                duplicatedStates.push(state.name);
            } else {
                stateKeys.add(state.name);
            }
        }

        const linkKeys = new Set<string>();
        const duplicatedLinks: string[] = [];
        for (const link of parsed.links) {
            const key = linkToKey(link);
            if (linkKeys.has(key)) {
                duplicatedLinks.push(`${link.from}-->${link.to}`);
            } else {
                linkKeys.add(key);
            }
        }

        let msg: string|string[];
        if (duplicatedStates.length === 0 && duplicatedLinks.length === 0) {
            msg = "No duplication found."
        } else {
            const msgs = ["Duplication found."];
            duplicatedStates.forEach(s => msgs.push(`- State: ${s}`));
            duplicatedLinks.forEach(l => msgs.push(`- Link: ${l}`));
            msg = msgs;
        }
        dialogContext.showDialog("Duplication", msg, "OK");
    }

    const refreshViews = () => {
        const parsed = parseRawText(editingUml, true);
        setHeaderText(parsed.headerText);
        setAllStates(parsed.states);
        setAllLinks(parsed.links);
        setAllViews(allViews.map(v => refreshView(v, parsed.states, parsed.links)));
    }

    /**
     * 新しいstateリスト、linkリストに含まれていないstate,linkを削除したviewを返す
     */
    const refreshView = (original: ViewInfo, allStates: State[], allLinks: Link[]): ViewInfo => {
        const allStateSet = new Set(allStates.map(s => s.name));
        const allLinkSet = new Set(allLinks.map(l => linkToKey(l)));

        return {
            name: original.name,
            stateNameSet: new Set([...original.stateNameSet].filter(s => allStateSet.has(s))),
            linkKeySet: new Set([...original.linkKeySet].filter(l => allLinkSet.has(l)))
        }
    }

    const onAddView = () => {

        let newNameNo = 1;
        let newName: string;
        while(true) {
            const tmpName = `View${newNameNo}`;
            if (!allViews.some(v => v.name === tmpName)) {
                newName = tmpName;
                break;
            }
            newNameNo++;
        }
        const newIdx = allViews.length;
        setAllViews([ ...allViews, { name:newName, linkKeySet:new Set(), stateNameSet:new Set() }]);
        setCurrentViewIndex(newIdx);
        props.onContentChangedChange(true);
    }

    const onViewNameChange = (name: string) => {
        if (currentViewIndex == null) return;
        setAllViews(allViews.map((v,i) => i !== currentViewIndex ? v : ({ ...v, name })));
        props.onContentChangedChange(true);
        setIsViewNameEditing(false);
    }

    const onDeleteView = async () => {
        if (currentView == null) return;

        const res = await dialogContext.showDialog("Delele View", `Are you sure you want to delete view "${currentView.name}" ?`, "DELETE_CANCEL");
        if (res !== 0) return;

        setCurrentViewIndex(undefined);
        setAllViews(allViews.filter(v => v !== currentView));
        props.onContentChangedChange(true);
    }

    const onCloneView = async () => {
        if (currentView == null || currentViewIndex == null) return;

        let newViewName = currentView.name + "_copy";
        let suffix = 1;
        const allViewNames = allViews.map(v => v.name);
        while(true) {
            if (!allViewNames.includes(newViewName)) break;
            newViewName = `${currentView.name}_copy${suffix}`;
            suffix++;
        }

        const newView: ViewInfo = { ...currentView, name:newViewName };
        setAllViews([
            ...allViews.slice(0, currentViewIndex + 1),
            newView,
            ...allViews.slice(currentViewIndex + 1)
        ]);
        setCurrentViewIndex(currentViewIndex + 1);
        props.onContentChangedChange(true);
    }

    return (<>
        <Header
            onImport={onImport}
            onExport={onExport}
            onAnalyzeCoverage={() => setIsCoverageShown(true)}
            onAboutClick={() => setIsAboutShown(true)}
        />
        <section className="section">
            <div className="container">
                <div className="columns">
                    <div className="column is-half">
                        <UmlInputPanel
                            uml={editingUml}
                            onUmlChanged={onUmlChange}
                            onCheckDuplication={onCheckDuplication}
                            onOpenModalRequrest={() => setIsUmlEditorShown(true)}
                        />
                        <ViewsPanel
                            allStates={allStates}
                            allLinks={allLinks}
                            onAddView={onAddView}
                            currentViewIndex={currentViewIndex}
                            onCurrentViewIndexChanged={setCurrentViewIndex}
                            views={allViews}
                            onViewUpdated={(idx,view) => {
                                setAllViews(allViews.map((v,i) => i === idx ? view : v));
                                props.onContentChangedChange(true);
                            }}
                            onRefreshRequest={refreshViews}
                        />
                    </div>

                    <div className="column is-half">
                        { currentView != null && (
                            <div className="panel">
                                <div className="panel-heading is-flex is-justify-content-space-between">
                                    <span>
                                        <span>Preview: {currentView.name}</span>
                                        <IconButton faClass='fas fa-pen' type='text' onClick={() => setIsViewNameEditing(true)} title="edit view name" />
                                    </span>
                                    <span>
                                        <IconButton faClass="fas fa-clone" type="warning" onClick={onCloneView} title="clone view"
                                            className="mr-1"
                                        />
                                        <IconButton faClass='far fa-trash-alt' type='danger' onClick={onDeleteView} title="delete view" />
                                    </span>
                                </div>
                                <div className="panel-block">
                                    { currentViewUml != null && (
                                        <div className="box">
                                            <PlantUmlView uml={currentViewUml} />
                                        </div>
                                    )}
                                </div>
                                <div className="panel-block">
                                    { currentViewUml != null && (
                                        <div className="control align-items-stretch is-flex">
                                            <textarea className="textarea is-small" rows={10} readOnly={true} value={currentViewUml} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="panel">
                            <AdUnit />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        { isCoverageShown && (
            <CoverageModal
                allStates={allStates}
                allLinks={allLinks}
                allViews={allViews}
                onClose={() => setIsCoverageShown(false)}
            />
        )}
        { isAboutShown && (
            <AboutModal
                onClose={() => setIsAboutShown(false)}
                version={APP_VERSION}
            />
        )}
        { isViewNameEditing && (
            <ViewNameEditModal
                onClose={() => setIsViewNameEditing(false)}
                onSubmit={onViewNameChange}
                name={currentView?.name ?? ""}
            />
        )}
        { isUmlEditorShown && (
            <UmlInputModal
                uml={editingUml}
                onUmlChange={onUmlChange}
                onClose={() => setIsUmlEditorShown(false)}
            />
        )}
    </>)
}