import Enumerable from "linq";
import { State, Link, UmlInfo, linkToKey } from "./UmlTypes";

export const LINE_BREAK = "\n";

export const toRawText = (uml: { headerText: string, states: Readonly<State[]>, links: Readonly<Link[]> }) => {
    const body = [
        "",
        ...toStateLines(uml.states),
        "",
        ...uml.links.map(l => toLinkLine(l))
    ].join(LINE_BREAK);

    return uml.headerText + body;
}

const toStateLines = (states: Readonly<State[]>): string[] => {
    const rest = [...states];

    const res = new Array<string>();
    while(rest.length > 0) {
        res.push(..._toStateLines(rest, 0));
    }
    return res;
}
const _toStateLines = (rest: State[], depth: number): string[] => {
    if (rest.length === 0) return [];
    const rtn = new Array<string>();
    const state = rest.splice(0, 1)[0];
    
    const indent = Enumerable.repeat(" ", depth * 2).toJoinedString("");
    const type = state.type == null ? "" : `<<${state.type}>>`;
    const line = `${indent}state "${state.dispName}" as ${state.name}${type}`;

    let childFound = false;
    while(true) {
        if (rest.length === 0) break;
        if (rest[0].parent !== state.name) break;

        if (!childFound) {
            childFound = true;
            rtn.push(line + " {");
        }

        rtn.push(..._toStateLines(rest, depth + 1));
    }
    if (childFound) {
        rtn.push(indent + "}");
    } else {
        rtn.push(line);
    }
    return rtn;
}



const toLinkLine = (link: Link): string => {
    const type = link.type == null ? "" : `[${link.type}]`;
    return `${link.from} -${type}-> ${link.to}${link.comment === "" ? "" : ": "}${link.comment}`
}


export const parseRawText = (text: string, distinct: boolean): UmlInfo => {
    //複数行コメントの除去
    while(true) {
        const stIdx = text.indexOf("/'")
        if (stIdx < 0) break;
        const edIdx = text.indexOf("'/", stIdx + 1);
        if (edIdx < 0) break;
        text = text.substring(0, stIdx) + text.substring(edIdx + 2);
    }

    const lines = text.split(LINE_BREAK).map(l => l.trimEnd()).filter(l => l !== "" && !l.trimStart().startsWith("'"));

    const headerLines = Enumerable.from(lines)
                                .takeWhile(l => !l.trimStart().startsWith("state "))
                                .toArray();
    const stateLines = Enumerable.from(lines)
                                .skip(headerLines.length)
                                .select(l => l.trim())
                                .takeWhile(l => l.startsWith("state ") || l === "}")
                                .toArray();
    const linkLines = Enumerable.from(lines)
                                .skip(headerLines.length + stateLines.length)
                                .select(l => l.trim())
                                .toArray();

    const headerText = headerLines.join(LINE_BREAK);

    const states = parseStates(stateLines, distinct);

    const links = parseLinks(linkLines, distinct);

    return { headerText, states, links };
}

const parseLinks = (lines: string[], distinct: boolean) => {
    const links = lines.map(l => parseLink(l)).filter(l => l != null).map(l => l!);

    if (!distinct) return links;

    const keys = new Set<string>();
    const res: Link[] = [];
    links.forEach(l => {
        const k = linkToKey(l);
        if (!keys.has(k)) {
            keys.add(k);
            res.push(l);
        }
    });

    return res;
}

const parseLink = (line: string): Link | undefined => {
    const fromEndIdx = line.indexOf("-");
    if (fromEndIdx < 0) {
        console.log("invalid link", line);
        return undefined;
    }
    const from = line.substr(0, fromEndIdx).trimEnd();
    let rest = line.substr(fromEndIdx).trimStart();
    let type: string | undefined;
    if (rest.startsWith("-->")) {
        type = undefined;
        rest = rest.substr("-->".length).trimStart();

    } else if (rest.startsWith("-[")) {
        const typeEndIdx = rest.indexOf("]-");
        if (typeEndIdx < 0) {
            console.log("invalid link", line);
            return undefined;
        }
        type = rest.substr("-[".length, typeEndIdx - "-[".length);
        rest = rest.substr(("-[" + type + "]->").length).trimStart();
    } else {
        console.log("invalid link", line);
        return undefined;
    }

    let to: string;
    let comment: string;
    const toEndIdx = rest.indexOf(":");
    if (toEndIdx < 0) {
        to = rest.trim();
        comment = "";
    } else {
        to = rest.substr(0, toEndIdx).trim();
        comment = rest.substr(toEndIdx + 1).trimStart();
    }

    return { from, to, comment, type };
}

const parseStates = (lines: Readonly<string[]>, distinct:boolean): State[] => {
    const states = new Array<State>();

    const rest = [...lines];
    while (rest.length > 0) {
        states.push(...parseState(rest));
    }

    if (!distinct) return states;

    const resMap = new Map<string, State>();
    states.forEach(st => {
        if (!resMap.has(st.name)) {
            resMap.set(st.name, st)
        }
    });

    return [...resMap.values()];
}

const REG_DISP_NAME_TYPES = [
    /^state "(.+)" as (.+)<<(.+)>>( *\{)$/,
    /^state (.+) as (.+)<<(.+)>>( *\{)$/,
    /^state "(.+)" as (.+)<<(.+)>>/,
    /^state (.+) as (.+)<<(.+)>>/,
];
const REG_DISP_NAMES = [
    /^state "(.+)" as (.+)( *\{)$/,
    /^state (.+) as (.+)( *\{)$/,
    /^state "(.+)" as (.+)/,
    /^state (.+) as (.+)/,
];
const REG_NAME_TYPES = [
    /^state (.+)<<(.+)>>( *\{)$/,
    /^state (.+)<<(.+)>>/,
];
const REG_NAMES = [
    /^state (.+)( *\{)$/,
    /^state (.+)/,
];
const parseStateLine = (line: string): Omit<State, "parent"> | undefined => {
    let res = Enumerable.from(REG_DISP_NAME_TYPES).select(r => r.exec(line)).firstOrDefault(r => r != null);
    if (res != null) {
        return { dispName: res[1].trim(), name: res[2].trim(), type: res[3].trim() };
    }
    res = Enumerable.from(REG_DISP_NAMES).select(r => r.exec(line)).firstOrDefault(r => r != null);
    if (res != null) {
        return { dispName: res[1].trim(), name: res[2].trim() };
    }
    res = Enumerable.from(REG_NAME_TYPES).select(r => r.exec(line)).firstOrDefault(r => r != null);
    if (res != null) {
        return { dispName: res[1].trim(), name: res[1].trim(), type: res[2].trim() };
    }
    res = Enumerable.from(REG_NAMES).select(r => r.exec(line)).firstOrDefault(r => r != null);
    if (res != null) {
        return { dispName: res[1].trim(), name:res[1].trim() };
    }

    return undefined;
}

const parseState = (restLines: string[], parent?: string): State[] => {
    let line = restLines.splice(0, 1)[0];

    const rtn = new Array<State>();
    
    const res = parseStateLine(line);
    if (res == null) {
        console.log("invalid line", line);
        return rtn;
    }

    rtn.push({ ...res, parent });

    if (line.endsWith("{")) {
        while(true) {
            if (restLines.length === 0) {
                console.log("invalid state nest");
                break;
            }
            if (restLines[0] === "}") {
                restLines.splice(0, 1);
                break;
            }

            rtn.push(...parseState(restLines, res.name));
        }
    }

    return rtn;
}