export type State = { name:string, dispName:string, type?:string, parent?:string };
export type Link = { from:string, to:string, comment:string, type?:string }
export interface UmlInfo {
    headerText: string;
    states: State[];
    links: Link[];
}

export interface ViewInfo {
    name: string;
    stateNameSet: Set<string>;
    linkKeySet: Set<string>;
}

export interface ProjectInfo {
    headerText: string;
    states: State[];
    links: Link[];
    rawUml: string;
    views: ViewInfo[];
}

export const linkToKey = (link: Pick<Link, "from"|"to">) => `${link.from}_>>>_${link.to}`;