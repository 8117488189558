import { useState } from "react"
import { PlantUmlView } from "./Preview/PlantUmlView";

interface Props {
    uml: string;
    onUmlChange: (uml:string) => void;
    onClose: () => void;
}

export const UmlInputModal = (props: Props) => {
    const [ currentView, setCurrentView ] = useState<"input"|"preview">("input");

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={props.onClose} />
            <div className="modal-content full">
                <div className="box is-flex is-flex-direction-column">
                    <div className="tabs is-centered">
                        <ul>
                            <li className={currentView === "input" ? "is-active" : ""}>
                                <a onClick={() => setCurrentView("input")}>Edit</a>
                            </li>
                            <li className={currentView === "preview" ? "is-active" : ""}>
                                <a onClick={() => setCurrentView("preview")}>Preview</a>
                            </li>
                        </ul>
                    </div>
                    { currentView === "input" && (
                        <textarea className="textarea is-small is-flex-grow-1"
                            value={props.uml}
                            onChange={e => props.onUmlChange(e.target.value)}
                        />
                    )}
                    { currentView === "preview" && (
                        <div className="box scroll-vertical is-flex-grow-1">
                            { props.uml !== "" && (
                                <PlantUmlView uml={props.uml} />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <button className="modal-close is-large" area-label="close" onClick={props.onClose} />
        </div>
    )

}