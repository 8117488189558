import React, { useEffect, useState } from 'react';
import { Content } from './Content';
import { Dialog, DialogButtonSet } from './Dialog';
import { DialogContext } from './DialogContext';

const App = () => {
  const [ currentDialog, setCurrentDialog ] = useState<{ header:string, message:string|string[], buttons:DialogButtonSet, onClose:(result?:number) => void }>();
  const [ changed, setChanged ] = useState(false);

  useEffect(() => {
    window.onbeforeunload = () => changed ? "" : undefined;
  }, [changed])

  const showDialog = (header: string, message: string|string[], buttons: DialogButtonSet): Promise<number|undefined> => {

    return new Promise(resolve => setCurrentDialog({
      header,
      message,
      buttons,
      onClose:resolve
    }));
  }

  const onDialogClose = (result?: number) => {
    if (currentDialog == null) return;
    const cb = currentDialog.onClose;
    setCurrentDialog(undefined);
    cb(result);
  }

  return (<>
    <DialogContext.Provider value={{ showDialog }}>
      <Content onContentChangedChange={setChanged} />
    </DialogContext.Provider>
    { currentDialog != null && (
      <Dialog
        header={currentDialog!.header}
        message={currentDialog!.message}
        buttons={currentDialog!.buttons}
        onClose={onDialogClose}
      />
    )}
  </>);
}

export default App;
